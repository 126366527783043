.fc-event-main{
  color:white!important;
}
.alert-confirm-main {
  position: fixed;
  z-index: 10000024 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 190px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
