.bg-light-primary {
  background: rgb(115, 103, 240) !important;
  color: white !important;
}

.bg-light-info {
  background: #00CFE8 !important;
  color: white !important;
}
.bg-light-success{
  background: #28c76f !important;
  color: white !important;
}
.bg-light-warning {
  background: #FF9F43 !important;
  color: white !important;
}

.bg-light-danger {
  background: #AE3FC9 !important;
  color: white !important;
}
.bg-light-secondary {
  background: #82868b !important;
  color: white !important;
}

.alert-confirm-container * {
  box-sizing: border-box
}

.alert-confirm-container .alert-confirm-animation-in.alert-confirm-mask {
  -webkit-animation: fadeIn 200ms forwards;
  animation: fadeIn 200ms forwards
}

.alert-confirm-container .alert-confirm-animation-in.alert-confirm-main {
  -webkit-animation: zoomIn 200ms forwards;
  animation: zoomIn 200ms forwards
}

.alert-confirm-container .alert-confirm-animation-out.alert-confirm-mask {
  -webkit-animation: fadeOut 200ms forwards;
  animation: fadeOut 200ms forwards
}

.alert-confirm-container .alert-confirm-animation-out.alert-confirm-main {
  -webkit-animation: zoomOut 200ms forwards;
  animation: zoomOut 200ms forwards
}

.alert-confirm-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, .5);
  transition: background-color .2s
}

.alert-confirm-main {
  width: 380px;
  position: absolute;
  z-index: 10000000;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 26vh;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .1215686275), 0 6px 16px rgba(0, 0, 0, .0784313725), 0 9px 28px 8px rgba(0, 0, 0, .0509803922);
  max-width: 90vw
}

.alert-confirm-body {
  padding: 26px 22px 20px;
  min-height: 70px
}

.alert-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4
}

.alert-confirm-content {
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  padding-top: 10px
}

.alert-confirm-content:first-child {
  padding-top: 0
}

.alert-confirm-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px 24px;
  font-size: 12px
}

.alert-confirm-footer > * {
  margin-left: 10px
}

.alert-confirm-button {
  border: 0;
  padding: 0 16px;
  line-height: 36px;
  cursor: pointer;
  color: #5c5c5c;
  border-radius: 2px;
  outline: none;
  background-color: #fff;
  transition: box-shadow .3s cubic-bezier(0.4, 0, 0.2, 1), color .3s;
  box-shadow: 0 -2px 5px 0 rgba(223, 200, 241, .3), 0 1px 0 0 rgba(223, 224, 241, .3), 0 1px 1px 0 rgba(162, 166, 191, .5), 0 2px 4px 0 rgba(228, 229, 242, .2)
}

.alert-confirm-button.default-button:hover, .alert-confirm-button.default-button:focus {
  box-shadow: 0 2px 4px 0 rgba(162, 166, 191, .2), 0 2px 4px 0 rgba(162, 166, 191, .2), 0 4px 5px 0 rgba(162, 166, 191, .1);
}
.alert-confirm-button.default-button {
  color: white;
  background-color: #148bed;
}
.alert-confirm-button.primary-button {
  color: #148bed;
  background-color: white;
}

.alert-confirm-button.primary-button:hover, .alert-confirm-button.primary-button:focus {
  box-shadow: 0 3px 7px 0 rgba(139, 162, 236, .2), 0 2px 4px 0 rgba(86, 112, 216, .2), 0 4px 5px 0 rgba(71, 96, 196, .3)
}

.alert-confirm-button.danger-button {
  background-color: #e3524e;
  color: #fff
}

.alert-confirm-button.danger-button:hover, .alert-confirm-button.danger-button:focus {
  box-shadow: 0 3px 7px 0 rgba(209, 129, 129, .2), 0 2px 4px 0 rgba(221, 130, 130, .2), 0 4px 5px 0 rgba(206, 85, 85, .3)
}

.alert-confirm-button:active {
  filter: brightness(96%)
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0.6) translateY(-100px)
  }
  100% {
    transform: scale(1) translateY(0)
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.6) translateY(-100px)
  }
  100% {
    transform: scale(1) translateY(0)
  }
}

@-webkit-keyframes zoomOut {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1
  }
  100% {
    transform: scale(0.6) translateY(-100px);
    opacity: 0
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1
  }
  100% {
    transform: scale(0.6) translateY(-100px);
    opacity: 0
  }
}
.fc-thirty-button{
  color: white!important;

}
.fc-thirty-button:hover{
  color: white!important;
  background-color: #2C3E50!important;
}
.fc-list-event-title{
  color: #090909 !important;
}
